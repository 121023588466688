class RelativeTimeFormatter {
  relativeTimeFormatter: Intl.RelativeTimeFormat;
  now: Date;
  lastYear: Date;
  lastMonth: Date;
  lastWeek: Date;
  lastDay: Date;
  lastHour: Date;
  lastMinute: Date;
  lastSecond: Date;

  constructor() {
    this.relativeTimeFormatter = new Intl.RelativeTimeFormat("en", {
      numeric: "auto",
    });
    this.now = new Date();
    const params = {
      year: this.now.getFullYear(),
      month: this.now.getMonth(),
      day: this.now.getDate(),
      hour: this.now.getHours(),
      minute: this.now.getMinutes(),
      second: this.now.getSeconds(),
      millisecond: this.now.getMilliseconds(),
    }
    this.lastYear = this.newDate({...params, year: params.year - 1});
    this.lastMonth = this.newDate({...params, month: params.month - 1});
    this.lastWeek = this.newDate({...params, day: params.day - 7});
    this.lastDay = this.newDate({...params, day: params.day - 1});
    this.lastHour = this.newDate({...params, hour: params.hour - 1});
    this.lastMinute = this.newDate({...params, minute: params.minute - 1});
    this.lastSecond = this.newDate({...params, second: params.second - 1});
  }

  newDate(params: { year: number; month: number; day: number, hour: number; minute: number; second: number; millisecond: number; }) {
    return new Date(params.year, params.month, params.day, params.hour, params.minute, params.second, params.millisecond);
  }

  format(date: Date) {
    if (date <= this.lastYear) {
      return this.relativeTimeFormatter.format(
        date.getFullYear() - this.now.getFullYear(),
        "year"
      );
    }
    if (date <= this.lastMonth) {
      const monthDiff = (this.now.getMonth() + 12 - date.getMonth()) % 12;
      return this.relativeTimeFormatter.format(-monthDiff, "month");
    }
    const msDiff = this.now.getTime() - date.getTime() ;
    const secondDiff = Math.floor(msDiff / 1000);
    const minuteDiff = Math.floor(secondDiff / 60);
    const hourDiff = Math.floor(minuteDiff / 60);
    const dayDiff = Math.floor(hourDiff / 24);
    const weekDiff = Math.floor(dayDiff / 7);
    if (date <= this.lastWeek) {
      return this.relativeTimeFormatter.format(-weekDiff, "week");
    }
    if (date <= this.lastDay) {
      return this.relativeTimeFormatter.format(-dayDiff, "day");
    }
    if (date <= this.lastHour) {
      return this.relativeTimeFormatter.format(-hourDiff, "hour");
    }
    if (date <= this.lastMinute) {
      return this.relativeTimeFormatter.format(-minuteDiff, "minute");
    }
    return this.relativeTimeFormatter.format(-secondDiff, "second");
  }
}

const RELATIVE_TIME_FORMATTER = new RelativeTimeFormatter();

addEventListener("load", function () {
  this.document
    .querySelectorAll("time")
    .forEach(function (el: HTMLTimeElement) {
      const date = new Date(el.getAttribute("datetime")!);
      el.innerText = RELATIVE_TIME_FORMATTER.format(date);
    });
});
